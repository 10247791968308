import React, { useState } from 'react';
import { graphql, navigate } from 'gatsby';
import {
  Typography,
  Grid,
  TextField,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import { Button } from 'gatsby-theme-material-ui';
import { init, send } from 'emailjs-com';
import PageLayout from '../components/pageLayout';
import { genHelmet, validateEmail } from '../actions/baseActions';

import theme from '../gatsby-theme-material-ui-top-layout/theme';

// EmailJS
init('user_aL2vCdR2e8iSo4GFfsYsx');

const styles = {
  root: {
    paddingTop: '60px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    // maxWidth: '800px',
  },
  wrapper: {
    padding: theme.spacing(2),
  },
  mailingWrapper: {
    paddingTop: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(8),
  },
  progress: {
    color: '#000',
    margin: 'auto',
  },
  linearProgress: {
    background: 'rgba(0, 0, 0, 0.87)',
  },
  formGrid: {
    '& > div': {
      paddingBottom: theme.spacing(3),
    },
  },
  form: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
};

const Contact = (props) => {
  const pageData = props.data.contentfulPage;

  const [email, setEmail] = useState('');
  const [body, setBody] = useState('');
  const [name, setName] = useState('');
  const [emailSending, setEmailSending] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [errorMsg, setErrMsg] = useState('');
  const [listEmail, setListEmail] = useState('');
  const [listErrorMsg, setListErrMsg] = useState('');
  const [listSending, setListSending] = useState(false);
  const [listSent, setListSent] = useState(false);

  const handleSubmit = (ev) => {
    ev.preventDefault();

    if (!validateEmail(email)) return setErrMsg('email appears invalid');

    setEmailSending(true);
    send('default_service', 'template_cpfe6pp', {
      name,
      email,
      body,
    }).then(
      (res) => {
        console.log('SUCCESS!', res.status, res.text);
        setErrMsg('');
        setEmailSending(false);
        setEmailSent(true);
      },
      (err) => {
        console.log('FAILED', err);
        setEmailSending(false);
        setErrMsg(
          'Oops! The email did not go through. Please try again or give us a call!'
        );
      }
    );
  };

  const handleMailingListSubmit = (ev) => {
    ev.preventDefault();

    if (!validateEmail(listEmail))
      return setListErrMsg('email appears invalid');

    setListSending(true);
    send('default_service', 'template_lpi4zdc', {
      email: listEmail,
    }).then(
      (res) => {
        // console.log("SUCCESS!", res.status, res.text);
        setListErrMsg('');
        setListSending(false);
        setListSent(true);
      },
      (err) => {
        console.log('FAILED', err);
        setListSending(false);
        setListErrMsg(
          'Oops! The sign up did not go through. Please try again or give us a call!'
        );
      }
    );
  };

  return (
    <React.Fragment>
      {genHelmet(
        { ...pageData, title: 'Contact' },
        {
          type: 'page',
          // siteData: pageData,
          path: props.path,
        }
      )}

      {/* --- LAYOUT --- */}
      <PageLayout location={props.location}>
        <Grid direction="row" container sx={styles.root}>
          {/* <Grid item className={classes.title}>
            <Typography variant="h3">Contact</Typography>
          </Grid> */}
          {!emailSending && !emailSent && (
            <Grid item sm={12} md={8} sx={styles.wrapper}>
              <Typography variant="h3" sx={styles.title}>
                Contact Us
              </Typography>
              <Typography>
                Let us know what you're working on. We'd be happy to help you
                create your next project or to simply provide some advice.
              </Typography>
              <form onSubmit={handleSubmit} style={styles.form}>
                <Grid container direction="column" sx={styles.formGrid}>
                  <TextField
                    variant="outlined"
                    label="Name"
                    value={name}
                    onInput={(ev) => setName(ev.target.value)}
                    error={Boolean(errorMsg && !errorMsg.includes('email'))}
                    helperText={errorMsg.includes('email') ? '' : errorMsg}
                    color="secondary"
                  />
                  <TextField
                    variant="outlined"
                    label="Email"
                    value={email}
                    onInput={(ev) => setEmail(ev.target.value)}
                    error={Boolean(errorMsg.includes('email'))}
                    helperText={errorMsg.includes('email') ? errorMsg : ''}
                    color="secondary"
                  />
                  <TextField
                    variant="outlined"
                    label="Message"
                    multiline
                    rows={8}
                    value={body}
                    onInput={(ev) => setBody(ev.target.value)}
                    color="secondary"
                  />
                </Grid>
                <Button variant="outlined" type="submit" color="secondary">
                  Send
                </Button>
              </form>
            </Grid>
          )}

          {emailSending && !emailSent && (
            <Grid item sm={12} md={8} sx={styles.wrapper}>
              <CircularProgress
                size={200}
                thickness={1}
                sx={styles.progress}
                fullscreen
              />
            </Grid>
          )}

          {emailSent && (
            <Grid item sm={12} md={8} container direction="column" spacing={8}>
              <Grid item>
                <Typography variant="h4">Email sent!</Typography>
              </Grid>
              <Grid item>
                <Typography>We'll be in touch with you shortly.</Typography>
                <Typography>
                  If the matter is urgent, please give us a call at +1
                  212.595.2193.
                </Typography>
              </Grid>
            </Grid>
          )}
          {!listSent && !listSending && (
            <Grid item sx={styles.wrapper} sm={12} md={8}>
              <Typography
                variant="h3"
                sx={{ ...styles.title, ...styles.mailingWrapper }}
              >
                Mailing List
              </Typography>

              <Typography>
                Sign up for the BVA mailing list for periodic updates.
              </Typography>
              <form onSubmit={handleMailingListSubmit} style={styles.form}>
                <Grid container direction="column" sx={styles.formGrid}>
                  <TextField
                    variant="outlined"
                    label="Email"
                    value={listEmail}
                    onInput={(ev) => setListEmail(ev.target.value)}
                    error={Boolean(listErrorMsg)}
                    helperText={listErrorMsg}
                    color="secondary"
                  />
                </Grid>
                <Button variant="outlined" type="submit" color="secondary">
                  Sign Up
                </Button>
              </form>
            </Grid>
          )}
          {listSending && !listSent && (
            <Grid item sm={12} md={8} sx={styles.wrapper}>
              <LinearProgress
                sx={styles.linearProgress}
                // size={200}
                // thickness={1}
                // sx={styles.progress}
                // fullscreen
              />
            </Grid>
          )}
          {listSent && (
            <Grid item direction="column" sm={12} md={8} sx={styles.wrapper}>
              <Typography variant="h4">Thank you for signing up!</Typography>
            </Grid>
          )}
        </Grid>
      </PageLayout>
    </React.Fragment>
  );
};

export default Contact;

export const pageQuery = graphql`
  {
    contentfulPage(slug: { eq: "/" }) {
      slug
      headline
      title
      coverImage {
        title
        description
        gatsbyImageData
        banner: gatsbyImageData(width: 1200, formats: JPG, sizes: "1200px")
        thumbnail: gatsbyImageData(width: 600, formats: JPG, sizes: "600px")
      }
    }
  }
`;
